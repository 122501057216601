.card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 16px;
  overflow: hidden;
  background-color: var(--color-surface-bg);
  border: 1px solid var(--color-surface-outline);
  border-radius: var(--border-radius-m);
}

.card__image > img {
  width: 100%;
  height: auto;
}

.card__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 16px 16px 0;
  background-color: var(--color-surface-bg);
  border-radius: var(--border-radius-m);
}

.card__content--remove-space {
  height: unset;
}

.card__icon {
  margin-bottom: 8px;
  color: var(--color-custom-link-fg);
}

.card__body {
  flex-grow: 1;
  margin-bottom: 8px;
}

.card__link-container {
  display: inline;
  padding: 0 16px;
}

.card__content--with-icon-aside-left {
  flex-direction: row;
}

.card__content--with-icon-top {
  flex-direction: column;
}

.card--variant-volt {
  border: 0;
}

.card--variant-volt::before {
  position: absolute;
  inset: 0;
  padding: 3px;
  pointer-events: none;
  content: '';
  background: var(--color-gradient-gradient-volt);
  border-radius: var(--border-radius-m);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: xor;
  mask-composite: exclude;
}
