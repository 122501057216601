@use '@virginmediao2/momentum-css/react/components/type';

.type:not(.type--color-inherit) {
  color: var(--color-surface-fg);
}

.type--color-light:not(.type--color-inherit) {
  color: var(--color-surface-bg);
}

.type--color-dark:not(.type--color-inherit) {
  color: var(--color-surface-fg);
}

.type--color-success:not(.type--color-inherit) {
  color: var(--color-success-bg);
}

.type--color-warning:not(.type--color-inherit) {
  color: var(--color-warning-bg);
}

.type--color-error:not(.type--color-inherit) {
  color: var(--color-error-bg);
}

.type--color-info:not(.type--color-inherit) {
  color: var(--color-info-bg);
}

.type--line-height-900 {
  line-height: var(--line-height-900);
}

.type--line-height-800 {
  line-height: var(--line-height-800);
}

.type--line-height-700 {
  line-height: var(--line-height-700);
}

.type--line-height-600 {
  line-height: var(--line-height-600);
}

.type--line-height-500 {
  line-height: var(--line-height-500);
}

.type--line-height-400 {
  line-height: var(--line-height-400);
}

.type--line-height-300 {
  line-height: var(--line-height-300);
}

.type--line-height-200-med {
  line-height: var(--line-height-200-med);
}

.type--line-height-200 {
  line-height: var(--line-height-200);
}

.type--line-height-100-med {
  line-height: var(--line-height-100-med);
}

.type--line-height-100 {
  line-height: var(--line-height-100);
}
