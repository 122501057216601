.vertical-rule {
  display: inline-block;
  width: 1px;
  height: 37px;
  background-color: var(--mo-color-20);
}

.vertical-rule--margin-xs {
  margin: 0 var(--padding-xs);
}

.vertical-rule--margin-sm {
  margin: 0 var(--padding-s);
}

.vertical-rule--margin-md {
  margin: 0 var(--padding-m);
}

.vertical-rule--margin-lg {
  margin: 0 var(--padding-l);
}

.vertical-rule--margin-none {
  margin: 0;
}
