.side-nav {
  padding: var(--padding-l);
  background-color: var(--color-surface-bg);
  border: 1px solid var(--color-surface-outline);
  border-radius: var(--border-radius-m);
}

.side-nav__heading {
  padding-bottom: 6px;
}

.side-nav__section {
  padding-inline: 0;
  margin: 0;
  list-style: none;
}

.side-nav__section-item {
  padding-block: 6px;
}

.side-nav__subsection {
  display: block;
  padding-block: var(--padding-m);
  padding-inline: calc(var(--padding-l) + var(--padding-m)) var(--padding-l);
  margin-inline: calc(var(--padding-l) * -1);
  margin-top: var(--padding-m);
  list-style: none;
  background-color: var(--color-surface-bg-dim);
}

.side-nav__subsection-item {
  padding-block: 6px;
}
