.feedback__button {
  height: unset;
  padding: 0 16px;
  color: var(--color-primary-bg);
}

.feedback {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 0 var(--mo-container-gutter);
  text-align: center;
}

@media (width <48em) {
  .feedback {
    flex-direction: column;
    gap: 2px;
  }
}

.feedback__buttons {
  display: flex;
  align-items: center;
}
