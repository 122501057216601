.peek {
  padding-top: var(--padding-m);
  background: var(--color-surface-fg);
  border-radius: var(--border-radius-l);
}

.peek--elevation-1 {
  box-shadow: var(--elevation-level-1);
}

.peek--elevation-2 {
  box-shadow: var(--elevation-level-2);
}

.peek--elevation-3 {
  box-shadow: var(--elevation-level-3);
}

.peek--elevation-4 {
  box-shadow: var(--elevation-level-4);
}

.peek__inner {
  padding: var(--padding-m);
  overflow: hidden;
  background: var(--color-surface-bg);
  border-radius: var(--border-radius-l);
}
